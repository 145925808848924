<template>
  <div class="bottomMenuPreview" :style="`z-index:8;position:sticky;justify-self:flex-end;`" v-if="bottomMenu">
     <div v-for="(menu,menuIndex) in bottomMenu.items" :key="menuIndex"  :style="`padding-top:5px;background:${menuIndex===0?bottomMenu.styles.activeBackground:bottomMenu.styles.background};`" class="bottomMenuPreview_item">
         <div :style="`
        min-height:${bottomMenu.styles.iconSize}px;min-width:${bottomMenu.styles.iconSize}px;
          -webkit-mask-image: url(${menu.icon});
          mask-image: url(${menu.icon});
          background-color: ${menuIndex===0?bottomMenu.styles.activeColor:bottomMenu.styles.color};`">
        </div>
         <span :style="`font-size:${ bottomMenu.styles.fontSize}px;color:${menuIndex===0?bottomMenu.styles.activeColor:bottomMenu.styles.color};font-weight:${ bottomMenu.styles.fontWeight}0;`">{{menu.text[landing.current_lang]}}</span>
    </div> 
    <div :style="`background:${ bottomMenu.styles.background};`" class="bottomMenuPreview_item">
        <v-icon :size="bottomMenu.styles.iconSize" :color="bottomMenu.styles.color">mdi-dots-horizontal</v-icon>
        <span :style="`font-size:${ bottomMenu.styles.fontSize}px;color:${     bottomMenu.styles.color};font-weight:${ bottomMenu.styles.fontWeight}0;`">menu</span>
    </div> 
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
    name:"BottomMenuPreview",
    data(){
        return{
            position:'sticky',
            pageMenu:''
        }
    },
    computed:{
        ...mapState(['landing','page']),
      ...mapState('branding',['bottomMenu']),
    },
}
</script>

<style lang="scss">
.bottomMenuPreview{
    height:60px;
    width: 100%;
    border-top:1px solid #eee;
    position: sticky;
    bottom:0;
    background:#fff;
    display:flex;
    align-items: center;
    &_item{
        display:flex;
        flex:1;
        height:100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-bottom: none;
    }
}
</style>